import React, { useCallback, useEffect, useState } from "react";
import { Spin, Select, DatePicker, Form, Button, Table, Input } from "antd";
import { ReadCookie } from "../../utils/readCookie";
import moment from "moment";
import btnPrint from "../../assets/images/button_print.gif";
import csv from "../../assets/images/csv.png";
import logo from "../../assets/images/logo-black.svg";
import "../Distributor/Distributor.css";
import { useRef } from "react";
import { CSVLink } from "react-csv";
import { TabTitle } from "../../utils/GeneralFunction";
import numeral from "numeral";
import { useDispatch, useSelector } from "react-redux";
import { getAdminLocation } from "../../redux/actions/adminLocation.action";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import ExcelJS from "exceljs";
import { getEcommerceByStyle } from "../../redux/actions/ecommerceReports.action";
const { Option } = Select;


const EcommerceByStyle = ({ token }) => {
  TabTitle("Ecommerce By Style")
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    ecommerceByStyle
  } = useSelector((state) => state);
  const menuId = ReadCookie("menuId");
  const userName = ReadCookie("name");
  const tableRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [ecommerceList, SetEcommerceList] = useState([]);
  const [excelData, setExcelData] = useState([])


  const onFinish = async (e) => {
    setLoading(true)
    await form.validateFields().then((data) => {
      dispatch(getEcommerceByStyle(token, data)).then((res => {
        setLoading(false)
        SetEcommerceList(res?.payload?.data);
        downloadExcelFileData(res?.payload?.data);
      }));
    });
  };


  const handlePrint = () => {
    window.print();
  };

  const colums = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      width: 40,
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Division",
      dataIndex: "gender_name",
      key: "gender_name",
      sorter: (a, b) => {
        if ((a.gender_name !== null) & (b.gender_name !== null)) {
          return a.gender_name.localeCompare(b.gender_name);
        }
      }
    },
    {
      title: "SKU",
      dataIndex: "item_code",
      key: "item_code",
      sorter: (a, b) => {
        if ((a.item_code !== null) & (b.item_code !== null)) {
          return a.item_code.localeCompare(b.item_code);
        }
      }
    },

    {
      title: "Inseam",
      dataIndex: "inseam",
      key: "inseam",
      sorter: (a, b) => {
        if ((a.inseam !== null) & (b.inseam !== null)) {
          return a.inseam.localeCompare(b.inseam);
        }
      }
    },
    {
      title: "Style",
      dataIndex: "style_name",
      key: "style_name",
      sorter: (a, b) => {
        if ((a.style_name !== null) & (b.style_name !== null)) {
          return a.style_name.localeCompare(b.style_name);
        }
      }
    },
    {
      title: "Wash",
      dataIndex: "wash_name",
      key: "wash_name",
      sorter: (a, b) => {
        if ((a.wash_name !== null) & (b.wash_name !== null)) {
          return a.wash_name.localeCompare(b.wash_name);
        }
      }
    },
    {
      title: "Shipped",
      dataIndex: "shipped",
      key: "shipped",
      align: "right",
      render: (text, record) => {
        return <> {numeral(text).format("0,00")}</>
      },
      sorter: (a, b) => {
        return a.shipped - b.shipped
      },
    },
    {
      title: "Return",
      dataIndex: "returned",
      key: "returned",
      align: "right",
      render: (text, record) => {
        return <> {numeral(text).format("0,00")}</>
      },
      sorter: (a, b) => {
        return a.returned - b.returned
      },
    },
    {
      title: "Net",
      dataIndex: "net",
      key: "net",
      align: "right",
      render: (text, record) => {
        return <> {numeral(text).format("0,00")}</>
      },
      sorter: (a, b) => {
        return a.net - b.net
      },
    },
    {
      title: "Return Rate",
      dataIndex: "return_percent",
      key: "return_percent",
      align: "right",
      render: (text, record) => {
        return <>{text}%</> 
      },
      sorter: (a, b) => {
        return a.return_percent - b.return_percent
      },
    },
  ];


  function downloadExcelFileData(data) {
    setExcelData(
      data.map((v) => {
        return {
          "Division": v.gender_name,
          "SKU": v.item_code,
          "Inseam": v.inseam,
          "Style": v.style_name,
          "Wash": v.wash_name,
          "Shipped": v.shipped,
          "Return": v.returned,
          "Net": v.net,
          "Return Rate": v.return_percent,
        };
      })
    )
  }



  return (
    <div className="header_class business_header_oms intransit2">
      <div className="row justify-content-between business_class_oms distributor" style={{width: "1000px"}}>
        <div className="col-3 d-flex align-items-start box-space">
          <div className="logobrand">
            <img id="ImgLogo" alt="logo" src={logo} />
          </div>
        </div>
        <div className="col-4 box-space right-content-header text-right">
          <div className=" d-flex justify-content-end">
            <div className=" d-flex justify-content-end mr-8">
              <div className="print_icon printing_class">
                <i
                  onClick={handlePrint}
                  id="Image1"
                  title="Print"
                  alt="Print"
                  src={btnPrint}
                  className="fa-solid fa-print"
                ></i>
              </div>
              <div className="print_icon printing_class" >
                <CSVLink
                  filename={"Ecommerce-By-Style.csv"}
                  data={excelData}
                  onClick={() => {
                  }}
                >
                  <input
                    type="image"
                    name="ImgExcel"
                    alt="Export to excel"
                    id="ImgExcel"
                    title="Export to excel"
                    src={csv}
                    style={{ borderWidth: "0px", height: "14px" }}
                  />
                </CSVLink>
              </div>
            </div>
            <span style={{ fontSize: "small", fontWeight: "bolder" }}>
              Ecommerce-By-Style
              <br />
              Rpt # 489
              <br />
              <p className=" mb-0">Rpt # 001_V4</p>
              Login as:{" "}
              <span id="lblSalesPerson" style={{ fontWeight: "bold" }}>
                {userName}
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className="business_class_oms mb-2 distributor"  style={{width: "1000px"}}>
        <Form form={form} name="control-hooks" onFinish={onFinish}>
          <div className="main_form row">
            <div className="col-12">
              <hr className="my-1" />
              <div className="row">
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width30" htmlFor="">From</label>
                    <div className="w-100">
                      <Form.Item
                        name="from_date"
                        className="full_date"
                        initialValue={moment().startOf('year')}
                      >
                        <DatePicker
                          size="small"
                          format={"MM-DD-YYYY"}
                          defaultValue={moment().format("MM-DD-YYYY")}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <div className=" d-flex flex-row align-items-baseline">
                    <label className="labels width30" htmlFor="">To</label>
                    <div className="w-100">
                      <Form.Item
                        name="to_date"
                        className="full_date"
                        initialValue={moment().endOf('year')}
                      >
                        <DatePicker
                          size="small"
                          format={"MM-DD-YYYY"}
                          defaultValue={moment().format("MM-DD-YYYY")}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-12">
                  <div style={{ marginTop: "4px", float: "right" }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="submit-btn-btm m-0"
                    >
                      Generate
                    </Button>
                  </div>
                </div>
              </div>
              <hr className="my-1" />
            </div>
          </div>
        </Form>
      </div>
      <div

        className="table_content international_order"
        style={{ maxWidth: '1000px' }}
      >
        <div>
          {ecommerceByStyle?.payload?.data !== undefined ?
            (
              ecommerceByStyle?.payload?.data.length > 0 ? (
                <Table
                  ref={tableRef}
                  size="small"
                  bordered={false}
                  type="company"
                  className="w-100 test04"
                  dataSource={ecommerceList}
                  columns={colums}
                  loading={ecommerceByStyle?.loading}
                  pagination={{ pageSize: 500 }}
                  scroll={{ x: 'max-content' }}
                  rowClassName="custom-row"
                  // onHeaderRow="custom-header"
                />
              ) : (
                <div className="no-Data">
                  {/* {} */}
                  <h1>No Data</h1>
                </div>
              )
            ) : (
              loading && (
                <div className="loader" style={{ marginTop: "20px" }}>
                  <Spin size="medium" />
                </div>
              )
            )
          }
        </div>
      </div>
    </div>
  );
};

export default EcommerceByStyle;
